import { FieldCallback } from '@area2k/use-form';
import { useCallback } from 'react';

import { SkillCategory } from '../../context';

import SkillItem from './SkillItem';

import { FirstStepFieldIdEnum } from './';

import Stack from '@/components/Stack';
import { Heading } from '@/components/Typography';
import SingleSelectableField from '@/form/SingleSelectableField';
import useMediaQuery from '@/hooks/useMediaQuery';

interface Props {
  options: any;
  callback?: FieldCallback<any>;
}

const SkillCategorySelectField = ({ options, callback }: Props) => {
  const phoneOnly = useMediaQuery('(max-width: 559px)');
  const renderCategory = useCallback(
    (item: SkillCategory) => <SkillItem dense={phoneOnly} item={item} />,
    [phoneOnly],
  );

  return (
    <Stack
      vertical
      align={phoneOnly ? 'start' : 'center'}
      gap={phoneOnly ? 6 : 24}
    >
      <Heading
        size={phoneOnly ? 'sm' : 'xl'}
        weight={phoneOnly ? 'medium' : 'semibold'}
      >
        Select job category
      </Heading>
      <Stack
        wrap
        css={{
          alignItems: phoneOnly ? 'stretch' : 'center',
          display: phoneOnly ? 'grid' : null,
          gridTemplateColumns: phoneOnly ? '1fr 1fr 1fr' : null,
        }}
        gap={phoneOnly ? 8 : 16}
        justify={phoneOnly ? 'start' : 'center'}
      >
        <SingleSelectableField<any>
          required
          callback={callback}
          fieldId={FirstStepFieldIdEnum.SKILL_CATEGORY}
          itemToKey={(item) => item.id}
          options={options}
          renderItem={renderCategory}
        />
      </Stack>
    </Stack>
  );
};

export default SkillCategorySelectField;
