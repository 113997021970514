import useModal from '@area2k/use-modal';
import {
  faCamera,
  faSyncAlt,
  faKey,
  faUserMinus,
} from '@fortawesome/free-solid-svg-icons';
import { Snackbar } from '@mui/material';
import { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import EditAdminInfoModal from './EditAdminInfoModal';
import PageSkeleton from './PageSkeleton';
import UpdatePasswordToCustomerAdminModal, {
  CustomerAdminItem,
} from './modals/UpdatePasswordToCustomerAdminModal';
import useChangeAvatarModal from './useChangeAvatarModal';

import Avatar from '@/components/Avatar';
import Card from '@/components/Card';
import ConfirmationModal from '@/components/ConfirmationModal';
import Page from '@/components/Page';
import PrimarySecondaryLayout from '@/components/PrimarySecondaryLayout';
import Stack from '@/components/Stack';
import TextStack from '@/components/TextStack';
import { Body, Small } from '@/components/Typography';
import { TIME_TO_REFRESH } from '@/constants/general';
import { V3Endpoints } from '@/constants/urls';
import Link from '@/elements/Link';
import {
  useDeviceLogoutMutation,
  useGetAdminCustomerQuery,
  useResendCustomerAdminInvitationMutation,
} from '@/graphql';
import useAuth from '@/hooks/useAuth';
import useMediaQuery from '@/hooks/useMediaQuery';
import UserChangePasswordModal from '@/modals/UserChangePasswordModal';
import ROUTES from '@/routes/routes';
import styled from '@/styles';
import useAnalytics from '@/util/analytics';
import axiosClient from '@/util/axios/axiosClient';

const Grid = styled('div', {
  display: 'grid',
  gridTemplateRows: 'auto auto auto',
  gridTemplateColumns: '1fr 1fr',
  rowGap: '24px',
  '@phoneOnly': {
    columnGap: '8px',
    gridTemplateColumns: '1fr',
  },
});

const CustomerAdmin = () => {
  const phoneOnly = useMediaQuery('(max-width: 559px)');
  const { adminId: adminIdByParam } = useParams();
  const { resetGoogleAnalytics } = useAnalytics();

  const { currentAdminIsCustomerAdmin, currentAdmin } = useAuth();
  const [successMsg, setSuccessMsg] = useState<string | null>(null);
  const [errorMsg, setErrorMsg] = useState<string | null>(null);

  const closeSuccesModal = () => {
    setSuccessMsg(null);
  };

  const closeErrorModal = () => {
    setErrorMsg(null);
  };

  const { data } = useGetAdminCustomerQuery({
    variables: {
      customerAdminId: adminIdByParam || currentAdmin!.id,
    },
    pollInterval: TIME_TO_REFRESH,
  });

  const [resendInvite, { loading }] =
    useResendCustomerAdminInvitationMutation();

  const [showConfirmationModal, hideConfirmationModal] = useModal<{
    userId: CustomerAdminItem['user']['id'];
  }>(
    ({ userId }) => (
      <ConfirmationModal
        acceptAction={() =>
          resendInvite({
            variables: {
              userId,
            },
          })
        }
        acceptButtonLabel="Send Invite"
        bodyContentText="Are you sure you want to resend the invitation to this Client Admin?"
        denyAction={() => {}}
        denyButtonLabel="Cancel"
        hideModal={hideConfirmationModal}
        loading={loading}
        title={`Resend Invitation`}
      />
    ),
    [data],
  );

  const showAvatarModal = useChangeAvatarModal(data?.customerAdmin);

  const [showEditModal, hideEditModal] = useModal<CustomerAdminItem>(
    (customerAdmin: CustomerAdminItem) => (
      <EditAdminInfoModal
        closeErrorModal={closeErrorModal}
        closeSuccessModal={closeSuccesModal}
        customerAdmin={customerAdmin}
        hideModal={hideEditModal}
        setErrorMsg={setErrorMsg}
        setSuccessMsg={setSuccessMsg}
      />
    ),
    [data],
  );

  // as tenant admin
  const [
    showUpdatePasswordToCustomerAdminModal,
    hideUpdatePasswordToCustomerAdminModal,
  ] = useModal<CustomerAdminItem>(
    (customerAdmin: CustomerAdminItem) => (
      <UpdatePasswordToCustomerAdminModal
        customerAdmin={customerAdmin}
        hideModal={hideUpdatePasswordToCustomerAdminModal}
      />
    ),
    [data],
  );

  // as customer admin
  const [
    showChangeUserCurrentPasswordModal,
    hideChangeUserCurrentPasswordModal,
  ] = useModal(
    () => (
      <UserChangePasswordModal hideModal={hideChangeUserCurrentPasswordModal} />
    ),
    [],
  );

  const [deviceLogout] = useDeviceLogoutMutation();
  const navigate = useNavigate();
  const [showDeleteConfirmationModal, hideDeleteConfirmationModal] = useModal(
    () => (
      <ConfirmationModal
        acceptAction={async () => {
          let resp = {};
          try {
            resp = await axiosClient.delete(
              V3Endpoints.DELETE_CUSTOMER_ACCOUNT,
            );
          } catch (err: any) {
            if (
              err?.response?.data?.message ===
              'You cannot close your account because you are the default contact for an account with unpaid invoices'
            ) {
              // eslint-disable-next-line no-throw-literal
              throw {
                response: {
                  data: {
                    key: `Unable to Delete Account - Default Contact for Billing Account`,
                    message: `You can't delete your account because you are the default contact for a billing account with pending invoices.Please coordinate with your team or account manager to remove yourself as the point of contact for the billing account. Email am@gravywork.com for assistance from GravyWork's account management team.`,
                    alertStatus: 'warning',
                  },
                },
              };
            } else {
              throw err;
            }
          }

          if ((resp as { data: { isSuccess: boolean } })?.data?.isSuccess) {
            await deviceLogout();
            resetGoogleAnalytics();
            navigate(ROUTES.signOut);
            window.location.reload();
          }
        }}
        acceptButtonLabel="Yes"
        bodyContentText="Are you sure you want to delete your account permanently?"
        denyAction={() => {}}
        denyButtonLabel="No"
        hideModal={hideDeleteConfirmationModal}
        loading={loading}
        title={`Delete Account`}
      />
    ),
    [data],
  );

  if (!data) {
    return (
      <PageSkeleton currentAdminIsCustomerAdmin={currentAdminIsCustomerAdmin} />
    );
  }

  const user = data.customerAdmin.user;
  const customerAdmin = data.customerAdmin;
  const customer = data.customerAdmin.customer;

  const permittedEdit = currentAdmin!.user.id === customerAdmin.user.id;

  const customerAdminAcceptInvation = customerAdmin.acceptedAt !== null;

  const permitResendInvitation =
    (!currentAdminIsCustomerAdmin || currentAdmin!.id !== customerAdmin.id) &&
    !customerAdminAcceptInvation;

  const resendInvitationAction = [
    {
      a11yLabel: 'Resend invitation',
      label: 'Resend Invitation',
      icon: faSyncAlt,
      id: 'btn-resend-invitation',
      onAction: () =>
        showConfirmationModal({
          userId: user.id,
        }),
    },
  ];

  const ChangeAvatarAction = [
    {
      a11yLabel: 'Change avatar',
      label: 'Change avatar',
      icon: faCamera,
      onAction: showAvatarModal,
      id: 'btn-change-avatar',
    },
  ];

  const DeleteAccountAction = [
    {
      a11yLabel: 'Delete Account',
      label: 'Delete Account',
      icon: faUserMinus,
      onAction: showDeleteConfirmationModal,
      id: 'btn-delete-account',
      dangerous: true,
    },
  ];

  const permittedEditPhoneAction = [
    {
      a11yLabel: 'Show more actions',
      label: 'More',
      actions: [
        [
          ...ChangeAvatarAction,
          {
            a11yLabel: 'Change password',
            label: 'Change password',
            icon: faKey,
            onAction: showChangeUserCurrentPasswordModal,
            id: 'btn-change-password',
          },
          ...DeleteAccountAction,
          ...(permitResendInvitation ? resendInvitationAction : []),
        ],
      ],
    },
  ];

  return (
    <Page
      headerPadding={phoneOnly}
      media={
        <Avatar
          firstName={data.customerAdmin.user.firstName}
          size="md"
          src={data.customerAdmin.avatarUrl}
        />
      }
      noPadding={phoneOnly}
      primaryAction={
        permittedEdit && !phoneOnly
          ? {
              a11yLabel: 'Change password',
              label: 'Change password',
              icon: faKey,
              onAction: showChangeUserCurrentPasswordModal,
            }
          : undefined
      }
      secondaryActions={
        !currentAdminIsCustomerAdmin
          ? [
              {
                a11yLabel: 'Show more actions',
                label: 'More',
                actions: [
                  [
                    ...ChangeAvatarAction,
                    {
                      a11yLabel: 'Change admin password',
                      label: 'Change password',
                      icon: faKey,
                      onAction: () =>
                        showUpdatePasswordToCustomerAdminModal(customerAdmin),
                    },
                    ...(permitResendInvitation ? resendInvitationAction : []),
                  ],
                ],
              },
            ]
          : permittedEdit
            ? [
                ...(!phoneOnly
                  ? [...DeleteAccountAction, ...ChangeAvatarAction]
                  : permittedEditPhoneAction),
              ]
            : permitResendInvitation
              ? resendInvitationAction
              : []
      }
      size={phoneOnly ? 'full' : 'lg'}
      title={`${user.firstName} ${user.lastName}`}
    >
      <PrimarySecondaryLayout css={{ gap: phoneOnly ? 0 : 24 }}>
        <PrimarySecondaryLayout.Primary>
          <Stack vertical gap={24}>
            <Card
              sectioned
              actions={
                !currentAdminIsCustomerAdmin || permittedEdit
                  ? [
                      {
                        a11yLabel: 'Edit admin info',
                        label: 'Edit',
                        onAction: () => showEditModal(customerAdmin),
                        id: 'btn-edit',
                      },
                    ]
                  : undefined
              }
              footerSpacing={phoneOnly}
              noRadius={phoneOnly}
              title="Information"
            >
              <Grid>
                <TextStack spacing="loose">
                  <Small>First Name</Small>
                  <Body>{user.firstName}</Body>
                </TextStack>
                <TextStack spacing="loose">
                  <Small>Last Name</Small>
                  <Body>{user.lastName}</Body>
                </TextStack>
                <TextStack spacing="loose">
                  <Small>Email Address</Small>
                  <Body>{user.email}</Body>
                </TextStack>
                <TextStack spacing="loose">
                  <Small>Phone Number</Small>
                  <Body>{user.phoneNumber}</Body>
                </TextStack>
                <TextStack spacing="loose">
                  <Small>Job Title</Small>
                  <Body color={customerAdmin.title ? 'default' : 'lightest'}>
                    {customerAdmin.title ? customerAdmin.title : 'Not provided'}
                  </Body>
                </TextStack>
              </Grid>
            </Card>
          </Stack>
        </PrimarySecondaryLayout.Primary>
        <PrimarySecondaryLayout.Secondary>
          <Card
            sectioned
            noRadius={phoneOnly}
            title={currentAdminIsCustomerAdmin ? 'Company' : 'Client'}
          >
            <Stack gap={phoneOnly ? 8 : 20}>
              <div>
                <Avatar
                  firstName={customer.name}
                  size="sm"
                  src={customer.logoUrl}
                />
              </div>
              <Link
                to={
                  currentAdminIsCustomerAdmin
                    ? `../../${ROUTES.myCompany}`
                    : `../../clients/${customer.id}`
                }
              >
                <Body color="inherit" weight="medium">
                  {customer.name}
                </Body>
              </Link>
            </Stack>
          </Card>
        </PrimarySecondaryLayout.Secondary>
        {successMsg && (
          <Snackbar
            autoHideDuration={2000}
            id="success-msg"
            message={successMsg}
            open={!!successMsg}
            onClose={closeSuccesModal}
          />
        )}

        {errorMsg && (
          <Snackbar
            ContentProps={{
              sx: {
                background: '#c23636',
                color: '#FFDFD8',
              },
            }}
            autoHideDuration={2000}
            id="error-msg"
            message={errorMsg}
            open={!!errorMsg}
            onClose={closeErrorModal}
          />
        )}
      </PrimarySecondaryLayout>
    </Page>
  );
};

export default CustomerAdmin;
