// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.counter-increment {
  border: 2px solid #45a735;
  svg {
    color: #45a735;
  }
}
.counter-increment[disabled] {
  border: 2px solid #d9d9d9;
  svg {
    color: #d9d9d9;
  }
}

.counter-increment:hover {
  background-color: #ddf5d9;
}

.counter-decrement {
  border: 2px solid #45a735;
  svg {
    color: #45a735;
  }
}

.counter-decrement[disabled] {
  border: 2px solid #d9d9d9;
  svg {
    color: #d9d9d9;
  }
}

.counter-decrement:hover {
  background-color: #ddf5d9;
}
`, "",{"version":3,"sources":["webpack://./src/components/Counter/index.css"],"names":[],"mappings":"AAAA;EACE,yBAAyB;EACzB;IACE,cAAc;EAChB;AACF;AACA;EACE,yBAAyB;EACzB;IACE,cAAc;EAChB;AACF;;AAEA;EACE,yBAAyB;AAC3B;;AAEA;EACE,yBAAyB;EACzB;IACE,cAAc;EAChB;AACF;;AAEA;EACE,yBAAyB;EACzB;IACE,cAAc;EAChB;AACF;;AAEA;EACE,yBAAyB;AAC3B","sourcesContent":[".counter-increment {\n  border: 2px solid #45a735;\n  svg {\n    color: #45a735;\n  }\n}\n.counter-increment[disabled] {\n  border: 2px solid #d9d9d9;\n  svg {\n    color: #d9d9d9;\n  }\n}\n\n.counter-increment:hover {\n  background-color: #ddf5d9;\n}\n\n.counter-decrement {\n  border: 2px solid #45a735;\n  svg {\n    color: #45a735;\n  }\n}\n\n.counter-decrement[disabled] {\n  border: 2px solid #d9d9d9;\n  svg {\n    color: #d9d9d9;\n  }\n}\n\n.counter-decrement:hover {\n  background-color: #ddf5d9;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
