import Config from '@/config';
import { GAEvent } from '@/constants/gaevents';
import Icon from '@/elements/Icon';
import Text from '@/elements/Text';
import styled from '@/styles';
import useAnalytics from '@/util/analytics';
import { faMobileScreen } from '@fortawesome/free-solid-svg-icons';

const Button = styled('div', {
  alignItems: 'center',
  display: 'flex',
  justifyContent: 'center',
  backgroundColor: '#444040',
  height: '40px',
  borderRadius: '8px',
  padding: '0 8px',
  '@phoneOnly': {},
});

const AppButton = () => {
  const { logEvent } = useAnalytics();

  const handleClick = () => {
    var isIOSDevice = /iPad|iPhone|iPod/.test(navigator.userAgent);
    logEvent(GAEvent.OpenInAppClickedFromPhone);
    if (isIOSDevice) {
      window.open(Config.APP_STORE_REDIRECT_URL, '_blank');
    } else {
      window.open(Config.PLAY_STORE_REDIRECT_URL, '_blank');
    }
  };
  return (
    <Button onClick={handleClick}>
      <Icon
        style={{ marginRight: '5px' }}
        icon={faMobileScreen}
        size="1x"
        theme={'white'}
      />
      <Text weight="bold" color="hi-contrast">
        Open in app
      </Text>
    </Button>
  );
};

export default AppButton;
