import { SubmitHelpers } from '@area2k/use-form';
import { parseISO } from 'date-fns';
import { useCallback, useEffect, useState } from 'react';

import { isHoliday as checkHoliday } from '../../CreateGigOrder/JobEditor/Steps/ScheduleStep/util';

import Button from '@/components/Button';
import Card from '@/components/Card';
import FormElement from '@/components/FormElement';
import MaskedInput from '@/components/MaskedInput';
import Modal from '@/components/Modal';
import Stack from '@/components/Stack';
import { Small, Body } from '@/components/Typography';
import { GAEvent } from '@/constants/gaevents';
import { COST_FACTOR, MAX_PAY } from '@/constants/rates';
import Form from '@/form';
import FormColumns from '@/form/FormColumns';
import { useGetSurgeRatesQuery, useUpdatePaymentJobMutation } from '@/graphql';
import { GetJobQuery, Scalars } from '@/types/graphql';
import useAnalytics from '@/util/analytics';
import { formatISODate } from '@/util/datetime';
import { handleMutationFormError } from '@/util/error';
import { centsToCurrency, currencyToCents } from '@/util/number';

export type Props = {
  job: GetJobQuery['job'];
  hideModal: () => Scalars['Void'];
};

type FormValues = {
  payRate: Scalars['String'];
  costRate: Scalars['String'];
};

const GLOBAL_MIN_PAY = 13;
const DEFAULT_RATE = 1.5;

const UpdatePaymentModal = ({ job, hideModal }: Props) => {
  const { minPayRate } = job;
  const isHoliday = job.isHoliday;
  const initPayRate = isHoliday
    ? job.originalPayRate || job.payRate / DEFAULT_RATE
    : job.payRate;
  const initCostRate = isHoliday
    ? job.originalCostRate || job.costRate / DEFAULT_RATE
    : job.costRate;

  const [holidayRates, setHolidayRates] = useState({
    payRate: job.payRate,
    costRate: job.costRate,
  });
  const { data } = useGetSurgeRatesQuery({
    variables: { fromDate: formatISODate() },
  });
  const holidays = data?.surgeRatesFromDate || [];

  const [formValues, setFormValues] = useState<FormValues>({
    payRate: centsToCurrency(initPayRate),
    costRate: centsToCurrency(initCostRate),
  });

  const { logEvent } = useAnalytics();

  useEffect(() => {
    if (isHoliday) {
      const holiday = checkHoliday(holidays, parseISO(job.firstShiftStartAt));
      if (holiday) {
        setHolidayRates({
          payRate: Math.floor(
            currencyToCents(formValues.payRate || '0') * holiday.rate,
          ),
          costRate: Math.floor(
            currencyToCents(formValues.costRate || '0') * holiday.rate,
          ),
        });
      }
    }
  }, [formValues, isHoliday, holidays]);

  const [confirm, setConfirm] = useState(false);

  const [updatePayments, { loading }] = useUpdatePaymentJobMutation({
    update: (cache) => {
      cache.modify({
        id: cache.identify(job),
        fields: {
          job() {},
        },
      });
    },
  });

  const handleInputChange = (
    key: Scalars['String'],
    value: Scalars['String'],
  ) => {
    const costRate = centsToCurrency(currencyToCents(value) * COST_FACTOR);

    return setFormValues({
      ...formValues,
      [key]: value,
      costRate: key === 'payRate' ? costRate : value,
    });
  };

  const handleSubmit = useCallback(
    async (values: FormValues, { setFormError }: SubmitHelpers) => {
      try {
        await updatePayments({
          variables: {
            jobId: job.id,
            originalPayRate: currencyToCents(formValues.payRate),
            originalCostRate: currencyToCents(formValues.costRate),
            payRate: isHoliday
              ? holidayRates.payRate
              : currencyToCents(formValues.payRate),
            costRate: isHoliday
              ? holidayRates.costRate
              : currencyToCents(formValues.costRate),
            isRateLock: false,
            markupPercent: null,
          },
        });
        logEvent(GAEvent.EditJobRates, job.id);
        hideModal();
      } catch (error) {
        handleMutationFormError(error, {
          setFormError,
          errorMap: {
            all: (gqlError) => ({
              title: gqlError.name,
              message: gqlError.message,
              status: 'danger',
            }),
          },
        });
      }
    },
    [formValues, holidayRates],
  );

  const MIN_PAY_RATE =
    minPayRate / 100 < GLOBAL_MIN_PAY ? GLOBAL_MIN_PAY : minPayRate / 100;

  const minValidPayRate =
    currencyToCents(formValues.payRate) / 100 < MIN_PAY_RATE;

  const maxValidPayRate = currencyToCents(formValues.payRate) >= MAX_PAY;
  const minValidCostRate =
    currencyToCents(formValues.costRate) < currencyToCents(formValues.payRate);

  const isDisabled =
    maxValidPayRate ||
    minValidPayRate ||
    minValidCostRate ||
    formValues.payRate === '' ||
    formValues.costRate === '';

  return (
    <Modal
      disableClickout
      size="xs"
      title={
        confirm
          ? 'Are you sure you want to edit these rates?'
          : 'Change Payment'
      }
      onRequestClose={hideModal}
    >
      <Card.Section>
        <Form initialValues={formValues} onSubmit={handleSubmit}>
          <FormColumns layout="double">
            <FormElement fontSize="md" label="Pay rate">
              <MaskedInput
                normalizeZeros
                padFractionalZeros
                disabled={confirm}
                mask={Number}
                radix="."
                scale={2}
                signed={false}
                thousandsSeparator=","
                value={formValues.payRate}
                onAccept={(value) => handleInputChange('payRate', value)}
              />

              {minValidPayRate && (
                <Small color="danger">
                  Invalid pay rate amount. The minimum possible amount is $
                  {MIN_PAY_RATE}
                </Small>
              )}
              {maxValidPayRate && (
                <Small color="danger">
                  Invalid pay rate amount. The maximum possible amount is $
                  {MAX_PAY / 100 - 0.01}
                </Small>
              )}
            </FormElement>

            <FormElement fontSize="md" label="Bill rate">
              <MaskedInput
                normalizeZeros
                padFractionalZeros
                disabled={confirm}
                mask={Number}
                radix="."
                scale={2}
                signed={false}
                thousandsSeparator=","
                value={formValues.costRate}
                onAccept={(value) => handleInputChange('costRate', value)}
              />
              {minValidCostRate && (
                <Small color="danger">
                  Invalid bill rate amount. The minimum possible amount is $
                  {formValues.payRate}
                </Small>
              )}
            </FormElement>
          </FormColumns>

          {isHoliday && (
            <FormColumns layout="double">
              <FormElement fontSize="md" label="Holiday pay rate">
                <Body>${centsToCurrency(holidayRates.payRate)}</Body>
              </FormElement>
              <FormElement fontSize="md" label="Holiday bill rate">
                <Body>${centsToCurrency(holidayRates.costRate)}</Body>
              </FormElement>
            </FormColumns>
          )}

          {!confirm ? (
            <Stack justify="end">
              <Button
                a11yLabel="Submit form"
                disabled={isDisabled}
                isLoading={false}
                label="Save"
                type="button"
                onClick={(e) => {
                  e.preventDefault();
                  setConfirm(true);
                }}
              />
            </Stack>
          ) : (
            <Stack justify="end">
              <Button
                a11yLabel="deny"
                appearance="outline"
                label="Cancel"
                status="danger"
                type="button"
                onClick={(e) => {
                  e.preventDefault();
                  setConfirm(false);
                }}
              />
              <Button
                a11yLabel="accept"
                isLoading={loading}
                label="Accept"
                type="submit"
              />
            </Stack>
          )}
        </Form>
      </Card.Section>
    </Modal>
  );
};

export default UpdatePaymentModal;
