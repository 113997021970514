import styled from '@/styles';

const Text = styled('span', {
  color: '$textDefault',

  fontSize: '$md',
  fontWeight: '$normal',
  lineHeight: 1.5,

  '& > strong': {
    fontWeight: '$semi',
  },

  variants: {
    color: {
      default: {
        color: '$textDefault',
      },
      light: {
        color: '$textLight',
      },
      lighter: {
        color: '$textLighter',
      },
      lightest: {
        color: '$textLightest',
      },
      neutral: {
        color: '$textNeutral',
      },
      theme: {
        color: '$themeDarker',
      },
      themeDefault: {
        color: '$themeDefault',
      },
      success: {
        color: '$successDarker',
      },
      danger: {
        color: '$dangerDarker',
      },
      inherit: {
        color: 'inherit',
      },
      warning: {
        color: '$warningDarker',
      },
      black: {
        color: '$black',
      },

      'hi-contrast': {
        color: 'white',
      },
      iconColor: {
        color: '$themeDefault',
      },
    },
    size: {
      xs: {
        fontSize: '$xs',
      },
      sm: {
        fontSize: '$sm',
      },
      md: {
        fontSize: '$md',
      },
      lg: {
        fontSize: '$lg',
      },
      xl: {
        fontSize: '$xl',
      },
      xxl: {
        fontSize: '$xxl',
      },
      xxxl: {
        fontSize: '$xxxl',
      },
      inherit: {
        fontSize: 'inherit',
      },
    },
    weight: {
      normal: {
        fontWeight: '$normal',
      },
      medium: {
        fontWeight: '$medium',
      },
      semibold: {
        fontWeight: '$semi',
      },
      bold: {
        fontWeight: '$bold',
      },
      extraBold: {
        fontWeight: '$extraBold',
      },
      inherit: {
        fontWeight: 'inherit',
      },
    },
    style: {
      normal: {
        fontStyle: 'normal',
      },
      italic: {
        fontStyle: 'italic',
      },
      oblique: {
        fontStyle: 'oblique',
      },
    },
    textTransform: {
      capitalize: {
        textTransform: 'capitalize',
      },
    },
    wordWrap: {
      normal: {
        wordWrap: 'normal',
      },
      breakWord: {
        wordWrap: 'break-word',
      },
      initial: {
        wordWrap: 'initial',
      },
      inherit: {
        wordWrap: 'inherit',
      },
    },
    lineHeightProp: {
      small: {
        lineHeight: 1.2,
      },
    },
    textAlignProp: {
      right: {
        textAlign: 'right',
      },
      center: {
        textAlign: 'center',
      },
    },
    padding: {
      none: {
        padding: 0,
      },
      rightSm: {
        paddingRight: '50px',
      },
    },
  },
});

Text.displayName = 'stitches(Text)';

export default Text;
