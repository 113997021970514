import { useState, useEffect } from 'react';

import DateRangeInput, {
  DateContext,
} from '../../CreateGigOrder/JobEditor/Steps/ScheduleStep/ScheduleSection/DateRangeInput';
import { stateFilters } from '../../Dashboard/DashboardFilters/DashboardFilterModal';
import { CustomerOnboardingStatusEnums } from '../OnboradingStatusBadge';

import StackGroupCheckFilters from './StackGroupCheckFilters';

import Button from '@/components/Button';
import Card from '@/components/Card';
import Modal from '@/components/Modal';
import Stack from '@/components/Stack';
import { AllFiltersProps } from '@/filters';
import { CustomerFilterSetInput, TaxTypeEnum } from '@/types/graphql';
import { CustomerStatusEnum } from '@/util/accountstatus';

type Props = AllFiltersProps<CustomerFilterSetInput> & {
  hideModal: () => void;
};

export const taxTypeOptions = [
  { label: 'All', value: TaxTypeEnum.ALL },
  { label: 'W2', value: TaxTypeEnum.TAX_W2 },
];

const onboardingStatusOptions = [
  {
    label: 'Complete',
    value: CustomerOnboardingStatusEnums.COMPLETED,
  },
  {
    label: 'In Progress',
    value: CustomerOnboardingStatusEnums.IN_PROGRESS,
  },
];

const statusApprovalOptions = [
  {
    label: 'Approved',
    value: CustomerStatusEnum.APPROVED.toString(),
  },
  {
    label: 'Rejected',
    value: CustomerStatusEnum.REJECTED.toString(),
  },
  {
    label: 'Pending',
    value: CustomerStatusEnum.PENDING.toString(),
  },
  {
    label: 'Disabled',
    value: CustomerStatusEnum.DISABLED.toString(),
  },
];

const CustomerFiltersModal = ({
  filters,
  hideModal,
  onChangeFilter,
  onClearAll,
}: Props) => {
  const [able, setAble] = useState(false);
  const [filterParams, setFilterParams] = useState(filters);

  const handleOnChangeFilter = <K extends keyof CustomerFilterSetInput>(
    key: K,
    value: NonNullable<CustomerFilterSetInput[K]>,
  ) => {
    setFilterParams((prevValue) => ({ ...prevValue, [key]: value }));
  };

  const handleOnClearFilter = (key: keyof CustomerFilterSetInput) => {
    const updatedFilterParams = { ...filterParams };
    delete updatedFilterParams[key];
    setFilterParams(updatedFilterParams);
  };

  useEffect(() => {
    if (able && Object.keys(filterParams).length !== 0) {
      onClearAll();
      Object.keys(filterParams).map((key: any) => {
        onChangeFilter(key, filterParams[key]);
        return null;
      });
    }
  }, [able]);

  const minDate = new Date();

  const endDate = minDate;

  endDate.setDate(endDate.getDate() + 1);

  const [DateRanges, setDateRanges] = useState({
    from: filterParams?.createdAt?.from || undefined,
    to: filterParams?.createdAt?.to || undefined,
  });

  const [hasPlaceholder, setHasPlaceholder] = useState<Boolean>(true);

  const handleRangeChange = (date: Date, context: DateContext) => {
    let dateString;
    try {
      dateString = date.toISOString();
    } catch {}
    if (context === 'startDate') {
      setDateRanges({
        ...DateRanges,
        from: dateString,
      });
    }
    if (context === 'endDate') {
      setDateRanges({
        ...DateRanges,
        to: dateString,
      });
    }
  };

  useEffect(() => {
    handleOnChangeFilter('createdAt', { ...DateRanges });
    if (DateRanges.to && DateRanges.from) {
      setHasPlaceholder(false);
    }
  }, [DateRanges]);

  const mapFilterOptions = [
    {
      title: 'Profile Progress',
      filterKey: 'onboardingStatus',
      isVertical: true,
      items: onboardingStatusOptions,
    },
    {
      title: 'Account Approval',
      filterKey: 'status',
      isVertical: true,
      items: statusApprovalOptions,
    },
    {
      title: 'Billing Account States',
      filterKey: 'states',
      isVertical: false,
      items: stateFilters(),
    },
    {
      title: 'Tax Type',
      filterKey: 'jobTaxType',
      isVertical: false,
      items: taxTypeOptions,
    },
  ];

  return (
    <Modal
      disableClickout
      size="sm"
      title="Change Filters"
      onRequestClose={hideModal}
    >
      {mapFilterOptions.map((filterOption) => {
        return (
          <Card.Section key={filterOption.filterKey} title={filterOption.title}>
            <StackGroupCheckFilters
              filterKey={filterOption.filterKey as keyof CustomerFilterSetInput}
              filters={filterParams}
              isVertical={filterOption.isVertical}
              items={filterOption.items}
              onChangeFilter={handleOnChangeFilter}
              onClearFilter={handleOnClearFilter}
            />
          </Card.Section>
        );
      })}

      <Card.Section title="Creation Date">
        <DateRangeInput
          noLabels
          endDate={DateRanges.to}
          hasPlaceholder={hasPlaceholder || false}
          minDate={DateRanges.from}
          startDate={DateRanges?.from}
          onChange={handleRangeChange}
        />
      </Card.Section>
      <Card.Section>
        <Stack justify="end">
          <Button
            a11yLabel="Clear all customer filters"
            appearance="outline"
            id="btn-clear-all"
            label="Clear all"
            onClick={() => {
              onClearAll();
              hideModal();
            }}
          />
          <Button
            a11yLabel="Save all Customer filters"
            appearance="primary"
            id="btn-save-filter"
            label="Save"
            onClick={() => {
              setAble(true);
              hideModal();
            }}
          />
        </Stack>
      </Card.Section>
    </Modal>
  );
};

export default CustomerFiltersModal;
