import { useReactiveVar } from '@apollo/client';
import useModal from '@area2k/use-modal';
import { faUser } from '@fortawesome/free-regular-svg-icons';
import {
  faChevronDown,
  faSignOutAlt,
  faBars,
  faTimes,
} from '@fortawesome/free-solid-svg-icons';
import { useMemo } from 'react';
import { useNavigate } from 'react-router-dom';

import DropdownMenu from '../DropdownMenu';
import TextStack from '../TextStack';

import SignOutModal from './modals/SignOutModal';
import {
  Wrapper,
  DropdownWrapper,
  AvatarWrapper,
  BurgerMenu,
  Branding,
  Profile,
  NameContainer,
  ProfileField,
} from './styles';

import Avatar from '@/components/Avatar';
import Stack from '@/components/Stack';
import Icon from '@/elements/Icon';
import Text from '@/elements/Text';
import { useDeviceLogoutMutation } from '@/graphql';
import { useAppDispatch, useAppSelector } from '@/hooks/store';
import useMediaQuery from '@/hooks/useMediaQuery';
import useTenantAdminProfile from '@/hooks/useOpenProfile';
import { Role } from '@/routes/PrivateRoute';
import ROUTES from '@/routes/routes';
import {
  selectSidebarActive,
  setRefetchDashboardData,
  toggleSidebar,
} from '@/store/slices/layoutSlice';
import { GenericAction } from '@/types';
import { CurrentTenantAdminFragment } from '@/types/graphql';
import useAnalytics from '@/util/analytics';
import { currentAdminVar, currentTenantVar } from '@/util/apollo/cache';
import AppButton from '../AppButtton';
import { isWeb } from '@/util/platform';
import { useFeatureValue } from '@growthbook/growthbook-react';
import { FEATURE_TOGGLE } from '@/constants/featuretoggle';

const AppNavbar = () => {
  const navigate = useNavigate();

  const showSidebar = useAppSelector((state) => selectSidebarActive(state));
  const openAppLinkMobile = useFeatureValue(FEATURE_TOGGLE.OpenAppLink, false);

  const dispatch = useAppDispatch();

  const phoneOnly = useMediaQuery('(max-width: 559px)');

  const currentTenant = useReactiveVar(currentTenantVar);
  const currentAdmin = useReactiveVar(currentAdminVar);
  const { resetGoogleAnalytics } = useAnalytics();

  const [deviceLogout] = useDeviceLogoutMutation();

  const [showSignOutModal, hideSignOutModal] = useModal(
    () => (
      <SignOutModal
        onConfirm={async () => {
          window?.HubSpotConversations?.widget?.remove();
          await deviceLogout();
          resetGoogleAnalytics();
          navigate(ROUTES.signOut);
        }}
        onHide={hideSignOutModal}
      />
    ),
    [],
  );

  const { showProfile } = useTenantAdminProfile(
    currentAdmin as CurrentTenantAdminFragment,
  );

  const dropdownActions = useMemo((): GenericAction[][] => {
    const signOutAction: GenericAction = {
      a11yLabel: 'Sign out',
      icon: faSignOutAlt,
      label: 'Sign out',
      onAction: showSignOutModal,
      id: 'sign-out',
    };

    const goToMyCustomerProfileAction: GenericAction = {
      a11yLabel: 'My profile',
      icon: faUser,
      label: 'My profile',
      onAction: () => navigate(ROUTES.myProfile),
    };

    const goToMyTenantProfileAction: GenericAction = {
      a11yLabel: 'My profile',
      icon: faUser,
      label: 'My profile',
      onAction: showProfile,
    };

    const tenantAdminActions: GenericAction[] = [
      goToMyTenantProfileAction,
      signOutAction,
    ];

    const customerAdminActions: GenericAction[] = [
      goToMyCustomerProfileAction,
      signOutAction,
    ];

    return [
      currentAdmin?.role === Role.TENANT_ADMIN
        ? tenantAdminActions
        : customerAdminActions,
    ];
  }, [currentAdmin]);

  console.log('AppNavbar', openAppLinkMobile);
  return (
    <Wrapper>
      <BurgerMenu show={showSidebar} onClick={() => dispatch(toggleSidebar())}>
        <Icon icon={showSidebar ? faTimes : faBars} size="2x" />
      </BurgerMenu>
      {openAppLinkMobile && isWeb() && phoneOnly && <AppButton />}
      <Branding
        to={ROUTES.basePath}
        onClick={() => {
          if (window.location.pathname !== ROUTES.basePath) {
            dispatch(setRefetchDashboardData(true));
          }
        }}
      >
        {currentTenant ? (
          currentTenant.logoUrl ? (
            <img
              src={currentTenant.logoUrl}
              style={{ width: '100%', height: 36 }}
            />
          ) : (
            <Text color="inherit" size="lg" weight="semibold">
              {currentTenant.name}
            </Text>
          )
        ) : null}
      </Branding>
      <DropdownWrapper>
        <DropdownMenu
          actions={dropdownActions}
          justify="right"
          margin="rightLg"
          size={phoneOnly ? 'sm' : 'md'}
        >
          <Profile id="profile-dropdown">
            <Stack gap={20}>
              <AvatarWrapper>
                <Avatar
                  color
                  firstName={currentAdmin?.user.firstName}
                  size="sm"
                  src={currentAdmin?.avatarUrl}
                />
              </AvatarWrapper>
              <NameContainer>
                <TextStack spacing="tight">
                  <ProfileField>
                    {currentAdmin
                      ? `${currentAdmin.user.firstName} ${currentAdmin.user.lastName}`
                      : '...'}
                  </ProfileField>
                  <ProfileField light>
                    {currentAdmin ? currentAdmin.user.email : '...'}
                  </ProfileField>
                </TextStack>
              </NameContainer>
            </Stack>
            <Icon fixedWidth icon={faChevronDown} />
          </Profile>
        </DropdownMenu>
      </DropdownWrapper>
    </Wrapper>
  );
};

export default AppNavbar;
