import useModal from '@area2k/use-modal';
import { useFeatureValue } from '@growthbook/growthbook-react';
import { useEffect, useState } from 'react';

import { FirstStepFormValues, OnChangeFirstStepForm } from '../';
import { useOrderState } from '../../../../context';
import { Address } from '../../../context';

import RedirectionClientProfileAddressModal from './RedirectionClientProfileAddressModal';

import Button from '@/components/Button';
import LoadingState from '@/components/LoadingState';
import Stack from '@/components/Stack';
import { FEATURE_TOGGLE } from '@/constants/featuretoggle';
import { MAX_LENGTH_ADDRESS_INSTRUCTIONS } from '@/constants/text';
import { V3Endpoints } from '@/constants/urls';
import Text from '@/elements/Text';
import ItemSelectField from '@/form/ItemSelectField';
import TextAreaField from '@/form/TextAreaField';
import useMediaQuery from '@/hooks/useMediaQuery';
import ActiveAddressModal from '@/routes/Agency/Customer/AddressList/ActiveAddressModal';
import CreateAddressModal from '@/routes/Agency/Customer/AddressList/CreateAddressModal';
import styled from '@/styles';
import { Maybe } from '@/types';
import { Scalars } from '@/types/graphql';
import axiosClient from '@/util/axios/axiosClient';

type Props = {
  skill: FirstStepFormValues['skill'];
  hasInstructions?: Scalars['Boolean'];
  onChange: OnChangeFirstStepForm;
  address: FirstStepFormValues['address'];
  rateError: boolean;
};

const FormFieldWrapper = styled('div', {
  width: '100%',
});

const AddressSection = ({
  skill,
  address,
  hasInstructions = false,
  onChange,
  rateError,
}: Props) => {
  const phoneOnly = useMediaQuery('(max-width: 559px)');
  const { billing } = useOrderState();
  const [isOpen, setIsOpen] = useState(hasInstructions);
  const [addresses, setAddresses] = useState<Maybe<Address[]>>(null);
  const [loading, setLoading] = useState(false);

  const fetchAddresses = async () => {
    setLoading(true);
    try {
      const res = await axiosClient.get(
        `${V3Endpoints.GET_CUSTOMER_ADDRESSES}${billing?.customer.id}`,
      );
      setAddresses(res.data);
      setLoading(false);
    } catch (e) {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchAddresses();
  }, [billing]);

  const showRemovedAddressCreationOnOrder = useFeatureValue(
    FEATURE_TOGGLE.RemoveAddressCreationOnOrder,
    false,
  );
  const onChangeAddress = (address: Address) => {
    if (address.active) {
      onChange({ value: address }, 'address');
    }
  };

  const [showCreateAddressModal, hideCreateAddressModal] = useModal(
    () => (
      <CreateAddressModal
        customer={billing?.customer}
        hideModal={(addressCreated) => {
          hideCreateAddressModal();
          fetchAddresses();
          if (addressCreated) {
            if (!addressCreated.active) {
              showInactiveAddressModal();
            }
          }
        }}
        onChangeAddress={onChangeAddress}
      />
    ),
    [addresses],
  );

  const [
    showClientProfileRedirectionAddressModal,
    hideProfileRedirectionAddressModal,
  ] = useModal(
    () => (
      <RedirectionClientProfileAddressModal
        customerId={billing?.customer?.id}
        hideModal={() => {
          hideProfileRedirectionAddressModal();
        }}
      />
    ),
    [addresses],
  );

  const [showInactiveAddressModal, hideInactiveAddressModal] = useModal(
    () => (
      <ActiveAddressModal
        hideModal={hideInactiveAddressModal}
        text={
          'This address is in rejected state. Please select another address.'
        }
      />
    ),
    [addresses],
  );

  if (loading || !addresses) {
    return <LoadingState overlayColor="white" text="Loading addresses" />;
  }

  return (
    <FormFieldWrapper css={{ display: !skill && phoneOnly ? 'none' : 'block' }}>
      <ItemSelectField<Address>
        required
        callback={(fieldContext) => {
          onChange(fieldContext, 'address');
        }}
        disabled={!skill}
        fieldId="address"
        itemToKey={(item) => item.id}
        itemToString={(item) =>
          item
            ? [
                item.addressLine1,
                item.addressLine2 && item.addressLine2.trim(), // include only if not empty
                item.city && item.city.trim(), // include only if not empty
                item.state && item.state.trim(), // include only if not empty
              ]
                .filter(Boolean) // filters out any undefined, null, or empty string values
                .join(', ') // joins the remaining values with commas
            : ''
        }
        items={addresses}
        label="Where is the job?*"
        placeholder="Select an address..."
        selectedItem={address}
      />
      {rateError && (
        <Text as="p" color={'danger'} css={{ top: '-15px' }}>
          {`It looks like you're placing an order in a new region where we haven't
          set up rates for you yet. Please contact `}
          <a
            href={`mailto:am@gravywork.com`}
            id="from-email"
            style={{ color: 'blue', textDecoration: 'none' }}
          >
            am@gravywork.com
          </a>
          {' to get this resolved.'}
        </Text>
      )}
      <Stack vertical>
        <Button
          a11yLabel="Click to add a new address"
          appearance={phoneOnly ? 'outline' : 'plain'}
          disabled={!skill}
          label="Add new address"
          type="button"
          onClick={() => {
            showRemovedAddressCreationOnOrder
              ? showClientProfileRedirectionAddressModal()
              : showCreateAddressModal();
          }}
        />
        {isOpen ? (
          <FormFieldWrapper>
            <TextAreaField
              callback={(fieldContext) =>
                onChange(fieldContext, 'addressInstructions')
              }
              fieldId="addressInstructions"
              label="Address instructions"
              maxLength={MAX_LENGTH_ADDRESS_INSTRUCTIONS}
              placeholder="Add any parking or supervisor check-in instructions..."
            />
          </FormFieldWrapper>
        ) : (
          <Button
            a11yLabel="Click to add optional address instructions"
            appearance={phoneOnly ? 'outline' : 'plain'}
            disabled={!skill}
            label="Add location instructions"
            type="button"
            onClick={() => setIsOpen(true)}
          />
        )}
      </Stack>
    </FormFieldWrapper>
  );
};

export default AddressSection;
