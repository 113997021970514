/* eslint-disable no-underscore-dangle */
// typescript globals
declare let __BUILD_COMMIT: string;
declare let __BUILD_VERSION: string;
declare let __BUILD_BRANCH: string;
declare let __BUILD_DATE: string;

export default {
  BASEPATH: process.env.BASEPATH,
  BUILD_COMMIT: __BUILD_COMMIT.substring(0, 7),
  BUILD_VERSION: __BUILD_VERSION,
  BUILD_BRANCH: __BUILD_BRANCH,
  BUILD_DATE: __BUILD_DATE,
  GRAPHQL_ENDPOINT: process.env.GRAPHQL_ENDPOINT!,
  SENTRY_DSN:
    process.env.SENTRY_DSN ||
    'https://bd885e9efde84ec2a399b97c294e838c@o264481.ingest.sentry.io/1466794',
  ENVIRONMENT: process.env.NODE_ENV || 'development',
  APP_ENVIRONMENT: process.env.APP_ENVIRONMENT,
  GROWTHBOOK_CLIENT_KEY: process.env.GROWTHBOOK_CLIENT_KEY,
  GROWTHBOOK_API_HOST: process.env.GROWTHBOOK_API_HOST,
  SHIFT_START_NOTIFICATION_EMAILS: process.env.SHIFT_START_NOTIFICATION_EMAILS,
  CHECKHQ_COMPANY: process.env.CHECKHQ_COMPANY,
  GOOGLE_API_KEY: process.env.GOOGLE_API_KEY,
  BASEURL_V3: process.env.BASEURL_V3,
  PUBNUB_PUBLISH_KEY: process.env.PUBNUB_PUBLISH_KEY,
  PUBNUB_SUBSCRIBE_KEY: process.env.PUBNUB_SUBSCRIBE_KEY,
  MAX_CHAT_LIMIT: process.env.MAX_CHAT_LIMIT || 200,
  BASE_URL: process.env.BASE_URL,
  APP_STORE_URL:
    process.env.APP_STORE_URL ||
    'https://apps.apple.com/us/app/gravywork-for-business/id6468312562',
  PLAY_STORE_URL:
    process.env.PLAY_STORE_URL ||
    'https://play.google.com/store/apps/details?id=com.gravywork.clientapp',
  PLAY_STORE_REDIRECT_URL: process.env.PLAY_STORE_REDIRECT_URL,
  APP_STORE_REDIRECT_URL: process.env.APP_STORE_REDIRECT_URL,
};
