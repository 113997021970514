import {
  addDays,
  differenceInCalendarDays,
  differenceInMinutes,
  differenceInSeconds,
  format,
  parse,
  parseISO,
  startOfToday,
} from 'date-fns';

import { OrderItem } from './job';

import {
  DATE_FORMAT,
  DATETIME_FORMAT,
  DAY_FORMAT,
  EVENT_DATE_FORMAT,
  EVENT_TIME_FORMAT,
  HUMAN_DATETIME_FORMAT,
  PLANE_DATE,
  PLANE_TIME,
  TIME_FORMAT,
  DAY_FORMAT_ORDER_SUBMISSION,
} from '@/constants/datetime';
import {
  parseStringsHours,
  toDate,
} from '@/routes/Agency/CreateGigOrder/JobEditor/Steps/ScheduleStep/util';
import { Schedule } from '@/routes/Agency/CreateGigOrder/JobEditor/context';
import { Scalars } from '@/types/graphql';

export const formatISODate = (date = new Date()) => {
  return format(date, PLANE_DATE);
};

export const formatEventDate = (date = new Date()) => {
  return format(date, EVENT_DATE_FORMAT);
};

export const formatDate = (date: Scalars['ISO8601Date']) => {
  return format(parse(date, PLANE_DATE, new Date()), DATE_FORMAT);
};

export const formatHumanDate = (date) => {
  return format(
    typeof date === 'string' ? parse(date, PLANE_DATE, new Date()) : date,
    DATE_FORMAT,
  );
};

export const formatDateTime = (datetime: Scalars['ISO8601DateTime']) => {
  return format(parseISO(datetime), DATETIME_FORMAT);
};

export const formatDay = (date) =>
  format(parse(date, PLANE_DATE, new Date()), DAY_FORMAT);

export const formatDaySimple = (date) => format(date, DAY_FORMAT);
export const formatDateSimple = (date) => format(date, DATE_FORMAT);
export const formatDaySimplewithYear = (date) =>
  format(date, DAY_FORMAT + ', yyyy');
export const formatDaySimpleWithTwoDigitYear = (date) =>
  format(date, DAY_FORMAT_ORDER_SUBMISSION + ', yy');

export const calcHours = (startTime, endTime) => {
  const st = parse(startTime, PLANE_TIME, new Date());
  const et = parse(endTime, PLANE_TIME, new Date());
  const diff = differenceInMinutes(et, st) / 60;
  if (diff < 0) {
    return diff + 24;
  }
  return diff;
};

export const formatTime = (datetime, timeFormat?: string) => {
  if (datetime) {
    return format(
      parse(datetime.replace(/T|Z/g, ' '), PLANE_TIME, new Date()),
      timeFormat || TIME_FORMAT,
    );
  }
  return null;
};

export const formatEventTime = (datetime) => {
  if (datetime) {
    return format(
      parse(datetime.replace(/T|Z/g, ' '), PLANE_TIME, new Date()),
      EVENT_TIME_FORMAT,
    );
  }
  return null;
};

export const getUserTimezoneOffset = () => {
  const offset = new Date().getTimezoneOffset() / 60;
  return offset * -1;
};

export const formatHumanDateTime = (datetime: string) =>
  format(new Date(datetime), HUMAN_DATETIME_FORMAT);

export const calculateTotalHours = ({
  schedules,
  job,
}: {
  schedules?: Schedule[];
  job?: OrderItem['jobs'][0];
}) => {
  const differenceInDays = (dateEnd, dateStart, job?) => {
    const difference = differenceInCalendarDays(dateEnd, dateStart);
    return difference + job && job.shifts.length === 1 && difference === 1
      ? 0
      : 1;
  };
  if (job) {
    const hoursPerSchedule: number[] = job.shifts.flatMap((shift) => {
      if (shift.endAt && shift.startAt) {
        return (
          differenceInSeconds(toDate(shift.endAt), toDate(shift.startAt)) *
          job.quantity *
          differenceInDays(toDate(shift.endAt), toDate(shift.startAt))
        );
      }
      return 0;
    });
    const totalHours = hoursPerSchedule.reduce((acc, h) => acc + h, 0);

    return totalHours;
  } else if (schedules) {
    const hoursPerSchedule: number[] = schedules.flatMap((sch) => {
      if (sch.startTime && sch.endTime) {
        return (
          differenceInSeconds(
            parseStringsHours(
              sch.endTime,
              sch.endTime < sch.startTime ? addDays(startOfToday(), 1) : null,
            ),
            parseStringsHours(sch.startTime, startOfToday()),
          ) *
          sch.quantity *
          differenceInDays(sch.dateRange.endDate!, sch.dateRange.startDate!)
        );
      }
      return 0;
    });
    const totalHours = hoursPerSchedule?.reduce((acc, h) => acc + h, 0) || 0;

    return totalHours;
  }
};

export const formatDuration = (time) => {
  const calculateHours = Math.floor(time / 3600);
  const hours = calculateHours < 10 ? '0' + calculateHours : calculateHours;
  const calculateMins = Math.floor((time / 60) % 60);
  const mins = calculateMins < 10 ? '0' + calculateMins : calculateMins;

  const duration =
    mins !== '00'
      ? hours.toString() + ':' + mins.toString() + ' hrs'
      : hours.toString() + ' hrs';

  return duration;
};

export const getJobCreationTime = (createdAt: string) => {
  const minutes = differenceInMinutes(new Date(), parseISO(createdAt));
  if (minutes > 1440) {
    return format(new Date(createdAt), 'PP');
  } else if (minutes > 59) {
    return Math.floor(minutes / 60) + ' hours ago';
  } else {
    return `${minutes} minutes ago`;
  }
};
