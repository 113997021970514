import { startOfWeek } from 'date-fns';

import { TaxTypeEnum } from '@/types/graphql';

export const KeyNames = {
  Down: 'ArrowDown',
  Enter: 'Enter',
  Escape: 'Escape',
  Left: 'ArrowLeft',
  Right: 'ArrowRight',
  Space: ' ',
  Up: 'ArrowUp',
};

export const LONG_DATE_FORMAT = 'eee, MMMM d, yyyy';
export const NOT_AVAILABLE_TEXT = 'N/A';
export const STATE_RECIPROCITY = ['DC', 'MD', 'VA'];

export const TAX_TYPE_LABELS = {
  [TaxTypeEnum.TAX_W2]: 'W2',
  [TaxTypeEnum.ALL]: 'All',
};

export const JOB_TAX_TYPES = {
  w2: 'w2',
  1099: '1099',
  all: 'all',
};

export const MONDAY = 1;

export const DAY_HOURS = 24;

export const MONDAY_OF_WEEK = startOfWeek(new Date(), { weekStartsOn: MONDAY });

export const MONDAY_OF_WEEK_UTC = new Date(
  Date.UTC(
    MONDAY_OF_WEEK.getFullYear(),
    MONDAY_OF_WEEK.getMonth(),
    MONDAY_OF_WEEK.getDate(),
  ),
);

export const ERROR_SUPPORT_MESSAGE =
  'Something went wrong, please contact support';

export const EXTERNAL_URL = {
  w2: 'https://console.checkhq.com/companies/{0}/employees/{1}',
  1099: 'https://console.checkhq.com/companies/{0}/contractors/{1}',
};

export const CONFIRM_MSG = 'Are you sure you wants to save this information?';
export const LOW_MARKUP_WARNING_DESCRIPTION =
  'The configured markup appears low. We recommend seeking approval from the Sales team.';
export const DEFAULT_MARKUP_PRICE_VALUE = 60;
export const MARKUP_PERCENTAGE_FIELD_ERROR = 'Markup percentage cannot be zero';
export const CONFIGURABLE_CHECKIN_FIELD_ERROR =
  'Please enter a value between 250 ft - 10,000 ft';

export const CLIENT_BADGE_UPDATED =
  "Badge requirements added or removed here will only apply to future job postings. To update badge requirements for current open jobs, please adjust badge settings directly within each job's details.";

export const PullToRefreshScreens = {
  includesList: [
    '/',
    '/clients',
    '/orders',
    '/contacts',
    '/workers',
    '/job-monitor',
    '/reports',
  ],
  startsWithList: ['/invoices'],
};

export const taxOptions = [TaxTypeEnum.ALL, TaxTypeEnum.TAX_W2];

export const allowedStates = ['VA', 'DC', 'MD', 'FL'];

export const isNullOrEmpty = (value) => {
  return value === null || value === undefined || value === '';
};
