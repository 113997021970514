import { JobDraftState } from '../routes/Agency/CreateGigOrder/JobEditor/context';

import Av from '@/components/Avatar';
import Stack from '@/components/Stack';
import styled from '@/styles';
import { Scalars } from '@/types/graphql';

const StyledAvatar = styled(Av, {
  marginLeft: -12,

  variants: {
    type: {
      Worker: {
        border: '2px solid white',
      },
      Skill: {
        border: '1px solid $colors$neutralLight',
        backgroundColor: 'white',
      },
    },
    small: {
      true: {
        marginLeft: 0,
        marginRight: 4,
      },
      false: {},
    },
  },
  defaultVariants: {
    type: 'Worker',
    small: false,
  },
});

const StyledText = styled('span', {
  variants: {
    type: {
      Worker: {},
      Skill: { marginLeft: 4 },
    },
  },
  defaultVariants: {
    type: 'Worker',
  },
});

export enum TypeAvatarGroups {
  Worker = 'Worker',
  Skill = 'Skill',
}

type Props = {
  items: JobDraftState['selectedWorkers'] | Scalars['String'][];
  type?: TypeAvatarGroups;
  maxLimitToShow?: Scalars['Int'];
  small?: boolean;
};

const MAX_AVATARS_TO_SHOWN = 3;

const AvatarGroupComponent = ({
  items,
  type = TypeAvatarGroups.Worker,
  maxLimitToShow = MAX_AVATARS_TO_SHOWN,
  small = false,
}: Props) => (
  <Stack
    css={{
      flexDirection: !small ? 'row-reverse' : 'row',
      width: !small ? '100%' : 'auto',
    }}
    gap={0}
    {...(type === TypeAvatarGroups.Skill
      ? { justify: !small ? 'center' : 'start' }
      : {})}
  >
    {items.length > maxLimitToShow && (
      <StyledText type={type}>+{items.length - maxLimitToShow}</StyledText>
    )}
    {items.map((item, index) =>
      index < maxLimitToShow ? (
        <StyledAvatar
          key={index}
          rounded
          size={!small ? (type === TypeAvatarGroups.Worker ? 'sm' : 'm') : 'xs'}
          small={small}
          src={type === TypeAvatarGroups.Worker ? item.avatarUrl : item}
          type={type}
          {...(type === TypeAvatarGroups.Worker
            ? { firstName: item.user.firstName }
            : {})}
        />
      ) : null,
    )}
  </Stack>
);

export default AvatarGroupComponent;
